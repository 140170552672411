// eslint-disable-next-line import/no-extraneous-dependencies
import { utcToZonedTime, format } from 'date-fns-tz';

export default class Schedule {
  constructor(entries) {
    this.entries = entries;
    window.utcToZonedTime = utcToZonedTime;
  }

  // eslint-disable-next-line class-methods-use-this
  entriesTable(entries, timeZone) {
    const $title = $('<h3></h3>')
      .text(format(entries[0].time, 'eeee, LLLL d', { timeZone }))
      .attr('id', `day-${entries[0].time.toISOString().slice(0, 10)}`);
    const $table = $('<table></table>').addClass('table')
      .append(
        $('<tbody></tbody>')
          .append(
            entries
              .sort((a, b) => (a.time < b.time ? -1 : 1))
              .map((entry) => {
                const timeData = entry.timeAlt !== undefined ?
                  $('<div class="time-alt"></div>')
                    .html(entry.timeAlt)
                  : [
                    $('<div class="time"></div>')
                      .html(format(entry.time, 'h:mmaaa', { timeZone })),
                    $('<div class="timezone"></div>')
                      .html(format(entry.time, 'O', { timeZone }))
                  ];
                return $('<tr>')
                  .addClass($(entry.body).hasClass('entry-break') ? 'break' : null)
                  .append($('<td class="col-time"></td>')
                    .append(timeData))
                  .append($('<td></td>').html(entry.body));
              })
          )
      );

    return [$title, $table];
  }

  render(timeZone) {
    const groups = [];
    let currGroup = [];
    this.entries.forEach((entry) => {
      const zonedEntry = {
        ...entry,
        time: utcToZonedTime(entry.time, timeZone),
      };
      if (currGroup.length !== 0 && (
        currGroup[0].time.getFullYear() !== zonedEntry.time.getFullYear()
        || currGroup[0].time.getMonth() !== zonedEntry.time.getMonth()
        || currGroup[0].time.getDate() !== zonedEntry.time.getDate()
      )) {
        groups.push(currGroup);
        currGroup = [];
      }
      currGroup.push(zonedEntry);
    });
    if (currGroup.length > 0) {
      groups.push(currGroup);
    }
    return $('<div></div>').addClass('schedule-content')
      .append(...groups.map((group) => this.entriesTable(group, timeZone)));
  }
}
