// eslint-disable-next-line import/no-extraneous-dependencies
import parseISO from 'date-fns/parseISO';
import tz from './data/tz.json';
import tzBackwards from './data/tz-backwards.json';
import Schedule from './schedule';

const defaultTimezone = 'Europe/Paris';

function getCurrentTz() {
  let answer = defaultTimezone;
  if (Intl && Intl.DateTimeFormat && Intl.DateTimeFormat().resolvedOptions) {
    answer = Intl.DateTimeFormat().resolvedOptions().timeZone || defaultTimezone;
  }
  // Check if the timezone is an old one that has to be mapped to a current IANA value
  return tzBackwards.mapping[answer] ? tzBackwards.mapping[answer] : answer;
}

if ($('[data-component=schedule]').length > 0) {
  $('[data-component=schedule]').each((i, container) => {
    const $scheduleContainer = $(container);
    const entries = Array.from($scheduleContainer.find('.entries .entry'))
      .map((entry) => ({
        timeAlt: $(entry).attr('data-time-alt'),
        time: parseISO($(entry).attr('data-time')),
        body: $(entry).clone(),
      }));

    const schedule = new Schedule(entries);

    if ($scheduleContainer.find('[data-component=tz-selector]').length > 0) {
      const $selector = $scheduleContainer.find('[data-component=tz-selector]').first()
        .html(tz.timezones.map((id) => `<option value="${id}">${id.replace('_', ' ')}</option>`)
          .join(''))
        .selectpicker()
        .selectpicker('val', getCurrentTz())
        .on('change', () => {
          const timezone = $selector.selectpicker('val');
          if (timezone) {
            $scheduleContainer.html(schedule.render(timezone));
            $scheduleContainer.addClass('ready');
          }
        });
      $selector.trigger('change');
    } else {
      $scheduleContainer.html(schedule.render($scheduleContainer.attr('data-tz') || getCurrentTz()));
      $scheduleContainer.addClass('ready');
    }
  });
}
