// eslint-disable-next-line import/no-extraneous-dependencies
import parseISO from 'date-fns/parseISO';
import Countdown from 'countdown';

window.parseISO = parseISO;

// Countdown
$('.countdown').each((i, countdownElement) => {
  const toDate = parseISO($(countdownElement).attr('data-to'));
  const fields = ['months', 'days', 'hours', 'minutes', 'seconds'];
  const containers = {};
  fields.forEach((field) => {
    containers[field] = $(countdownElement).find(`.countdown-item-${field} .value`);
  });
  Countdown(
    (timeSpan) => {
      fields.forEach((field) => {
        containers[field].text(timeSpan[field]);
      });
    },
    // UTC start of the event: 31 August 2022, 7:30
    toDate,
    // eslint-disable-next-line no-bitwise
    Countdown.MONTHS | Countdown.DAYS | Countdown.HOURS | Countdown.MINUTES | Countdown.SECONDS
  );
});
