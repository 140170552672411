let cindyJSEnabled = true;
if (URLSearchParams) {
  const params = new URLSearchParams(window.location.search);
  if (params.has('nocindy')) {
    cindyJSEnabled = false;
  }
}

if (cindyJSEnabled) {
  $('[data-lazy-cindy-width]').each((i, element) => {
    let loaded = false;
    const $element = $(element);
    const breakpoint = $element.attr('data-lazy-cindy-width');
    $(window).on('resize', () => {
      if (!loaded && window.innerWidth >= breakpoint) {
        $element.attr('src', $element.attr('data-lazy-cindy-src'));
        loaded = true;
      }
    }).trigger('resize');
  });
}
